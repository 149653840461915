import axios from 'axios'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { getFiles } from '../../utils/getFiles'
import { extractAndCreateImageUrls, readBlobAsArrayBuffer } from '../../utils/readBlobAsArrayBuffer'
import { toastNotification, toastType } from '../../utils/toast'
import { QuoteQueryKey } from '../Quotes/SingleQuote/singleQuoteApiHooks'
import { TaskQueryKey } from '../Task/taskApiHooks'
import { mapApiResponseToInventory, setMultipleValuesReactForm } from './types/utils'

//INVENTORIES
const addInventory = (data) => {
  return axios.post('/inventories', data)
}

export const useAddInventory = (callback) => {
  return useMutation(addInventory, {
    onSuccess: (data) => {
      if (typeof callback == 'function' && data?.data?.id) {
        callback(data?.data?.id)
      }
    },
  })
}

const getInStockInventories = (options) => {
  return axios.get(`/inventories/in-stock`, { params: { options } })
}

export const useGetInStockInventories = (options) => {
  return useQuery(['in-stock', options], () => getInStockInventories(options), {
    keepPreviousData: true,
    refetchOnWindowFocus: false,
  })
}

const getWholesaleInventories = (options) => {
  return axios.get(`/inventories/wholesale`, { params: { options } })
}

export const useGetWholesaleInventories = (options) => {
  return useQuery(['wholesale', options], () => getWholesaleInventories(options), {
    keepPreviousData: true,
    refetchOnWindowFocus: false,
  })
}

const getInventory = (inventoryId) => {
  return axios.get(`/inventories/${inventoryId}`)
}

export const useGetInventory = (inventoryId, setValue) => {
  return useQuery(['inventory', inventoryId], () => getInventory(inventoryId), {
    refetchOnWindowFocus: false,
    onSuccess: ({ data }) => {
      const values = mapApiResponseToInventory(data)
      setMultipleValuesReactForm(values, setValue)
    },
  })
}

const updateInventory = (data) => {
  return axios.patch(`/inventories/${data.id}`, data)
}

export const useUpdateInventory = ({ inventoryId, onError }) => {
  const queryClient = useQueryClient()
  return useMutation(updateInventory, {
    onSuccess: () => {
      toastNotification({ message: 'Inventory Updated', type: 'success' })
      queryClient.invalidateQueries(['inventory', inventoryId])
      toastNotification({ message: 'Changes Saved', type: toastType.success })
    },
    onError: (error) => {
      if (typeof onError == 'function' && Object.keys(error.response.data.message).length) {
        onError(error.response.data.message)
      }
    },
  })
}

const downloadSpecs = (inventoryId) => {
  return getFiles({ url: `/inventories/${inventoryId}/documents/specs` }, { allowDownload: true })
}

export const useDownloadSpecs = (inventoryId) => {
  return useMutation(() => downloadSpecs(inventoryId), {
    enabled: !!inventoryId,

    onError: (error) => {
      if (error.response?.data?.message) {
        toastNotification({ message: error.response?.data?.message, type: toastType.error })
      }
    },
  })
}

// DOCUMENTS
const getUploadedDocuments = async (inventoryId, options) => {
  return axios.get(`/inventories/${inventoryId}/documents`, { params: { options } })
}

export const useUploadedDocuments = (inventoryId, options) => {
  return useQuery(['documents', options], () => getUploadedDocuments(inventoryId, options), {
    keepPreviousData: true,
    refetchOnWindowFocus: false,
  })
}

const downloadAllDocuments = async (inventoryId) => {
  return getFiles({ url: `/inventories/${inventoryId}/files/download`, keys: null })
}

export const useDownloadAllDocuments = (inventoryId) => {
  return useMutation(() => downloadAllDocuments(inventoryId), {
    onSuccess: () => {
      toastNotification({ message: 'Documents Downloaded', type: toastType.success })
    },
  })
}

// DELETE DOCUMENT
const deleteSingleDocument = async (key) => {
  const response = await axios.delete(`/inventories/documents/${key}`)
  return response.data
}

export const useDeleteSingleDocument = () => {
  const queryClient = useQueryClient()

  return useMutation(deleteSingleDocument, {
    onSuccess: async () => {
      await queryClient.invalidateQueries(['documents'])
      toastNotification({ message: 'Successfully deleted!', type: toastType.success })
    },
    onError: (error) => {
      if (error.response?.data?.message) {
        toastNotification({ message: error.response?.data?.message, type: toastType.error })
      }
    },
  })
}

// REPAIRS
const addRepair = ({ data, inventoryId }) => {
  return axios.post(`/inventories/${inventoryId}/repairs`, data)
}

export const useAddRepair = () => {
  const queryClient = useQueryClient()

  return useMutation(addRepair, {
    onSuccess: async () => {
      await queryClient.invalidateQueries(['repairs'])
      toastNotification({ message: 'Repair Added!', type: toastType.success })
    },
  })
}

const getRepairs = async (inventoryId, options) => {
  const response = await axios.get(`/inventories/${inventoryId}/repairs`, { params: { options } })

  return response
}

export const useGetRepairs = (inventoryId, options) => {
  return useQuery(['repairs', options, inventoryId], () => getRepairs(inventoryId, options), {
    keepPreviousData: true,
    refetchOnWindowFocus: false,
  })
}

const updateRepair = (data) => {
  return axios.patch(`/inventories/${data.inventoryId}/repairs/${data.id}`, data)
}

export const useUpdateRepair = () => {
  const queryClient = useQueryClient()
  return useMutation(updateRepair, {
    onSuccess: () => {
      queryClient.invalidateQueries(['repairs'])
      toastNotification({ message: 'Changes Saved', type: toastType.success })
    },
  })
}

const deleteRepair = async (id, inventoryId) => {
  const response = await axios.delete(`/inventories/${inventoryId}/repairs/${id}`)
  return response.data
}

export const useDeleteRepair = () => {
  const queryClient = useQueryClient()

  return useMutation(deleteRepair, {
    onSuccess: async () => {
      await queryClient.invalidateQueries(['repairs'])
      toastNotification({ message: 'Successfully deleted!', type: toastType.success })
    },
    onError: (error) => {
      if (error.response?.data?.message) {
        toastNotification({ message: error.response?.data?.message, type: toastType.error })
      }
    },
  })
}

// STOCK NUMBER
const getStockNumber = async () => {
  const response = await axios.get('/inventories/next-stock-number')

  return +response.data
}

export const useGetStockNumber = () => {
  return useQuery(['stockNumber'], getStockNumber, {
    refetchOnWindowFocus: false,
  })
}

const checkStockNumber = async ({ stockNumber }) => {
  const data = await axios.get(`/inventories/stock-number/is-valid/${stockNumber}`)
  return data.data
}

export const useCheckStockNumber = (callbackOnSuccess) => {
  return useMutation(checkStockNumber, {
    onSuccess: (data) => {
      callbackOnSuccess(data)
      if (!data) {
        toastNotification({
          message: 'The stock number is not available. Please try a different one.',
          type: toastType.error,
        })
      }
    },
    onError: (error) => {
      if (error.response?.data?.message) {
        toastNotification({ message: error.response?.data?.message, type: toastType.error })
      }
    },
  })
}

// GET READY
const createGetReady = ({ data, inventoryId }) => {
  return axios.post(`/inventories-get-ready/${inventoryId}`, data)
}

export const useCreateGetReady = () => {
  const queryClient = useQueryClient()

  return useMutation(createGetReady, {
    onSuccess: async () => {
      await queryClient.invalidateQueries(['get-ready'])
      await queryClient.invalidateQueries(QuoteQueryKey.QUOTE_INVENTORIES)
      toastNotification({ message: 'Get Ready Added!', type: toastType.success })
    },
  })
}

const findAllInventoryGetReady = async (inventoryId, options) => {
  const response = await axios.get(`/inventories-get-ready/${inventoryId}`, { params: { options } })

  return response
}

export const useFindAllInventoryGetReady = (inventoryId, options) => {
  return useQuery(['get-ready', options, inventoryId], () => findAllInventoryGetReady(inventoryId, options), {
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    retry: false,
  })
}

const deleteInventoryGetReady = async (id) => {
  const response = await axios.delete(`/inventories-get-ready/${id}`)
  return response.data
}

export const useDeleteInventoryGetReady = () => {
  const queryClient = useQueryClient()

  return useMutation(deleteInventoryGetReady, {
    onSuccess: async () => {
      await queryClient.invalidateQueries(['get-ready'])
      await queryClient.invalidateQueries(QuoteQueryKey.QUOTE_INVENTORIES)
      toastNotification({ message: 'Successfully deleted!', type: toastType.success })
    },
    onError: (error) => {
      if (error.response?.data?.message) {
        toastNotification({ message: error.response?.data?.message, type: toastType.error })
      }
    },
  })
}

const updateGetReady = (data) => {
  return axios.patch(`/inventories-get-ready/${data.poNumber}`, data)
}

export const useUpdateGetReady = () => {
  const queryClient = useQueryClient()
  return useMutation(updateGetReady, {
    onSuccess: () => {
      queryClient.invalidateQueries(['get-ready'])
      toastNotification({ message: 'Changes Saved', type: toastType.success })
    },
  })
}

// GET READY QUOTE
// should be one quote per inventory??
const getInventoryQuotes = async (inventoryId) => {
  const response = await axios.get(`/inventories-get-ready/${inventoryId}/quotes`)
  return response.data
}

const changeGetReadyStatus = (data) => {
  return axios.patch(`/inventories-get-ready/${data.poNumber}/status`, data)
}

export const useGetInventoryQuotes = (inventoryId) => {
  return useQuery(['inventory-quotes', inventoryId], () => getInventoryQuotes(inventoryId), {
    refetchOnWindowFocus: false,
    enabled: !!inventoryId,
  })
}

export const useGetReadyStatusChange = () => {
  const queryClient = useQueryClient()
  return useMutation(changeGetReadyStatus, {
    onSuccess: () => {
      queryClient.invalidateQueries(['get-ready'])
      queryClient.invalidateQueries(TaskQueryKey.TASKS)
      toastNotification({ message: 'Status Changed', type: toastType.success })
    },
  })
}

// Upload and Show Images
const uploadFiles = async (data) => {
  let inventoryId = null
  let isTitle = false
  let isDocument = false

  for (const [key, value] of data.entries()) {
    if (key === 'inventoryId') {
      const inventoryIdObject = JSON.parse(value)
      inventoryId = inventoryIdObject.inventoryId
    }
    if (key === 'isTitle') {
      const isTitleObject = JSON.parse(value)
      isTitle = isTitleObject.isTitle
    }
    if (key === 'isDocument') {
      const isDocumentObject = JSON.parse(value)
      isDocument = isDocumentObject.isDocument
    }
  }

  if (isTitle) {
    const response = await axios.post(`/inventories/${inventoryId}/titles`, data)
    return response.data
  } else if (isDocument) {
    const response = await axios.post(`/inventories/${inventoryId}/documents`, data)
    return response.data
  } else {
    const response = await axios.post(`/inventories/${inventoryId}/images`, data)
    return response.data
  }
}

export const useUploadFiles = (callbackOnSuccess) => {
  return useMutation(uploadFiles, {
    onSuccess: (data) => {
      callbackOnSuccess(data)
      toastNotification({ message: 'Upload Successful!', type: toastType.success })
    },
    onError: (error) => {
      if (error.response?.data?.message) {
        toastNotification({ message: error.response?.data?.message, type: toastType.error })
      }
    },
  })
}

const getInventoryImages = async (inventoryId, { callbacks }) => {
  try {
    const response = await axios.get(`/inventories/${inventoryId}/images`, { responseType: 'blob' })
    const blob = new Blob([response.data], { type: response.headers['content-type'] || response.headers['Content-Type'] })

    const arrayBuffer = await readBlobAsArrayBuffer(blob)
    const imageUrls = extractAndCreateImageUrls(arrayBuffer)

    callbacks?.onSuccess?.(imageUrls)
  } catch (error) {
    console.error('Error in downloadAndSendImages:', error)
    throw error
  }
}

export const useShowInventoryImages = (inventoryId, callbackOnSuccess) => {
  return useQuery(
    ['inventoryId'],
    async () => {
      return getInventoryImages(inventoryId, callbackOnSuccess)
    },
    {
      onError: (error) => {
        if (error.response?.data?.message) {
          toastNotification({ message: error.response?.data?.message, type: toastType.error })
        }
      },
      refetchOnWindowFocus: false,
    }
  )
}

// RESERVE TRUCK
const reserveTruck = (data) => {
  return axios.post('/inventories/reserve-truck', data)
}

export const useReserveTruck = (inventoryId) => {
  const queryClient = useQueryClient()

  return useMutation(reserveTruck, {
    onSuccess: () => {
      queryClient.invalidateQueries(['inventory', `${inventoryId}`])
      toastNotification({ message: 'You have successfully reserved a truck.', type: toastType.success })
    },
  })
}
