import axios from 'axios'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { useDispatch } from 'react-redux'

export const NotificationsQueryKey = {
  notifications: 'notifications',
  notificationsInformation: 'notifications-information',
}

const getUserNotificationsInformation = async () => {
  return axios.get(`/notifications/information`)
}

export const useUserNotificationsInformation = () => {
  return useQuery(['notifications-information'], () => getUserNotificationsInformation(), {
    refetchOnWindowFocus: false,
  })
}

const getUserNotifications = async (options) => {
  return axios.get(`/notifications`, { params: { options } })
}

export const useNotifications = (options) => {
  const dispatch = useDispatch()
  const queryClient = useQueryClient()
  return useQuery([NotificationsQueryKey.notifications, options.pagination.page], () => getUserNotifications(options), {
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    onSuccess: () => {
      queryClient.invalidateQueries([NotificationsQueryKey.notificationsInformation])
    },
  })
}

const updateNotification = async (notificationId, data, all = false) => {
  if (all) {
    const { data: response } = await axios.put(`/notifications/all`, data)
    return response
  } else {
    const { data: response } = await axios.put(`/notifications/${notificationId}`, data)
    return response
  }
}

export const useUpdateNotification = ({ callbacks = {} }) => {
  const queryClient = useQueryClient()
  const dispatch = useDispatch()
  return useMutation({
    mutationFn: ({ notificationId, data, all = false }) => updateNotification(notificationId, data, all),
    onSuccess: (response) => {
      queryClient.invalidateQueries([NotificationsQueryKey.notificationsInformation])
      queryClient.invalidateQueries([NotificationsQueryKey.notifications], { exact: false })
      callbacks?.onSuccess?.(response)
    },
  })
}
