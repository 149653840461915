import { useEffect, useRef } from 'react'
import { useQueryClient } from 'react-query'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { NOTIFICATION_EVENT } from '../constants/Notifications.constants'
import { NotificationsQueryKey } from '../modules/User/useNotifications'
import { WebsocketService } from '../services/WebsocketService'

export const useWebsocketNotifications = () => {
  const queryClient = useQueryClient()

  const { userId } = useSelector((state) => ({
    userId: state.User.user.id,
  }))

  const websocketService = new WebsocketService()
  const socket = useRef(null)

  useEffect(() => {
    const connectSocket = async () => {
      try {
        if (userId) {
          const soc = await websocketService.connectSocket({
            url: `${process.env.REACT_APP_BACKEND_URL}/notificationz`,
            data: { userId }, // map sockets to user id on server
          })

          socket.current = soc
        }

        if (socket?.current) {
          let toastId = null
          let message = ''
          let type = null

          socket.current.onAny((event, data) => {
            // todo invalidate page 0 of notifications
            queryClient.invalidateQueries([NotificationsQueryKey.notifications], { exact: false })
            // queryClient.resetQueries({
            //   predicate: (query) => {
            //     // invalidate first page of notifications
            //     const condition = query.queryKey[0] === NotificationsQueryKey.notifications && query.queryKey[1] === 0 // page 0

            //     return condition
            //   },
            //   refetchInactive: true,
            //   refetchActive: true,
            //   refetch: true,
            // })

            queryClient.invalidateQueries([NotificationsQueryKey.notificationsInformation])

            if (event.startsWith('quote__')) {
              message = data?.message ?? 'Quote event'
              type = 'warning'
              toastId = message
            }

            if (event.startsWith('get_ready__')) {
              message = data?.message ?? 'Get Ready event'
              type = 'warning'
              toastId = message
            }

            // last line of code, display toast on each event
            if (message) {
              toast(message, { type, toastId, autoClose: true, closeOnClick: true })
              toastId = null
              message = null
              type = null
            }
          })

          socket.current.on(NOTIFICATION_EVENT.WELCOME, (data) => {
            message = data?.message ?? 'Welcome'
            type = 'info'
            toastId = message
          })

          socket.current.on(NOTIFICATION_EVENT.CONNECTED, (data) => {
            message = data?.message ?? 'Welcome'
            type = 'info'
            toastId = message
          })

          socket.current.on('connect_error', (error) => {
            console.error('Connection error:', error)
            message = 'Connection error'
            type = 'error'
            toastId = message
          })
        }
      } catch (error) {
        console.error('Failed to connect socket:', error)
      }
    }

    connectSocket()
  }, [userId, socket])

  return {}
}
