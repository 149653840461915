import axios from 'axios'
import { toastNotification, toastType } from './toast'

export const getFiles = async ({ url, keys, data = {} }, options = { allowDownload: true }) => {
  try {
    const config = {
      responseType: 'blob',
    }
    const response = await axios.post(
      url,
      {
        keys,
        ...data,
      },
      config
    )

    const contentDisposition = response.headers.get('Content-Disposition')
    let filename = `download-${Date.now()}.pdf` // Default filename

    if (contentDisposition) {
      const filenameMatch = contentDisposition.match(/filename="?(.+)"?/)
      if (filenameMatch.length > 1) {
        filename = filenameMatch[1]
      }
    }

    const contentType = response.headers['content-type'] ?? response.headers['Content-Type']

    const isZip = contentType?.includes('zip')
    const fileName = isZip ? 'documents.zip' : keys?.split('/')?.pop() ?? filename

    if (!response.data || response.data.size === 0) {
      toastNotification({ message: 'File is empty or corrupted.', type: toastType.error })
      return
    }

    const blob = new Blob([response.data], { type: contentType })
    const tempUrl = window.URL.createObjectURL(blob)

    // For PDFs and images, open in new tab. For other files, download
    if ((contentType?.includes('pdf') || contentType?.includes('image')) && !options?.allowDownload) {
      window.open(tempUrl, '_blank')
    } else {
      const link = document.createElement('a')
      link.href = tempUrl
      link.setAttribute('download', fileName)
      document.body.appendChild(link)
      link.click()
      link.remove()
    }

    // Cleanup
    window.URL.revokeObjectURL(tempUrl)
  } catch (error) {
    console.log('error', error)
    toastNotification({ message: 'File does not exist.', type: toastType.error })
  }
}
